// .homeIntroWrapper{  // Home intro with image
//     display: flex!important;
//     position: relative;
//     font-size: 1em; // reset the Fresco font increase
//     padding-bottom: 0;
//     &:before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         right: 0;
//         width: 100%;
//         height: 611px;
//         background-image: url($assets-path + 'features.png');
//         background-size: contain;
//         background-repeat: repeat-x;
//         background-position: center;
//     }
//     .template-wrapper {
//         .card {
//             background-color: transparent;
//             box-shadow: none;
//         }
//         .team img.mediaImage {
//             width: 600px;
//         }
//         .team .image-wrapper {
//             border-radius: 600px;
//             border: 1px solid white;
//             padding: 1rem;
//         }
//         h3 {
//             font-size: 3em;
//         }
//         @media (min-width: 768px) {
//             .horizontal.team .text-wrapper {
//                 padding: 0 0 0 50px;
//             }
//         }
//     }

// }

.homeIntroWrapper {
    display: block!important;
    background-color: $grey-dark;
    .homeIntro {
        text-align: center;
        margin: auto;
    }
}

// Make home features a circle
.homeFeatures .homeFeature [class*="image-link"] {
    border-radius: 600px;
    border: 1px solid white;
    padding: $spacer;
}
.homeFeatures .homeFeature .banner {
    border-radius: 600px;
}
// Add hover transition to home features
.homeFeatures .homeFeature {
    top: 0;
    transition-duration: 0.3s;
    &:hover {
        top: -1rem;
    }
}
// Full width logos in footer
.footerBox.footerBox1,
.footerBox.footerBox2 {
font-size: 1rem;
}
.footerBox.footerBox4 {
    grid-column: 1 / span 16;
    background-color: white;
    margin: $spacer * 2 0 0 0;
    padding: $spacer * 2;
    display: flex;
    justify-content: center;
    img {
        height: 100px;
        width: auto;
        margin: 0 $spacer * 2;
    }
    @media (max-width: map-get($breakpoints, md)) {
        display: block;
        img {
            margin: $spacer * 2 auto;
            display: block;
        }
    }
}
.pageFooter {
    padding-bottom: 0;
}

// Impact stats
.homefeaturecategory-homeboximpactstats .homeImpactWrapper {
    tbody {
        align-items: flex-start;
    }
    tr:nth-child(odd) {
        h2, td {
           // color: $brand-secondary;
        }
    }
}

// Force bold on strong
strong {
    font-weight: bold;
}

// Add speech bubble to blockquote
blockquote {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 8em;
        bottom: -30px;
        width: 70px;
        height: 70px;
        background-image: url($assets-path + 'quote.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.homeFeaturesWrapper, .homeFeatures {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 611px;
        background-image: url($assets-path + 'features.png');
        background-size: contain;
        background-repeat: repeat-x;
        background-position: center;
    }
}

.homeFeedBox2 .feedList .feedItem {
    &:hover {
        footer {
            background-color: $brand-secondary;
        }
    }
}

// DES-4573
.blogPostCreatePage .listingIntro {
    display: none;
}

.homeFeature.homefeaturecategory-homepageheropost {
    display: flex!important;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 40px 1.5rem 40px;
    display: flex;
    flex-direction: column;
    @media (min-width: map-get($breakpoints, md)) {
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
    }
    a.home-feature-image-link {
        border: 1px solid white;
        border-radius: 600px;
        padding: 1.5rem;
        @media (max-width: map-get($breakpoints, md)) {
            margin-bottom: 20px;
        }
    }
    .banner {
        @media (min-width: map-get($breakpoints, md)) {
            border-radius: 400px;
            min-width: 400px;
        }
        @media (min-width: map-get($breakpoints, lg)) {
            border-radius: 600px;
            min-width: 600px;
        }
        @media (max-width: map-get($breakpoints, md)) {
            border-radius: 768px;
            min-width: 100%;
        }
        z-index: 2;
    }
    .homeFeatureDetailsWrapper {
        padding-right: 40px;
        h2 a {
        color: $grey-dark;
        }
    }
    .featureFooter {
        display: none;
    }
}

.homeFeature.homefeaturecategory-homepageheropost {
    display: none;
}

.homeFeature.homefeaturecategory-homeboximpactstats {
    min-width: 100%;
    //margin: 1.5rem 0;

}

.homeIntroWrapper {
    padding: 40px 0;
   // background: $grey-dark;
    margin: 0;
}