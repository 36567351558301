//
// Environment
//


//
// Config values
//

$platform-name: 'linkagesouthwark';


//
// Colours
//

$brand-primary: #00a79d;
$brand-secondary: #80bd01;

$donate-colour: $brand-primary;

// Create greyscale
$black:             #000 !default;
$grey-dark:         rgb(39, 44, 70);
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;

$fresco-dark-font: #292b2c;




//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: "logo.png";
$logo-width: 377px;
$logo-height: 78px;


//
// Typography
//

$font-family-base: 'Source Sans Pro', sans-serif;
$font-size-base: 1.125rem;

$font-weight-normal: 300;
$font-weight-bold: bold !default;

$font-size-h1: 2.25em !default;
$font-size-h2: 1.75em !default;
$font-size-h3: 1.5em !default;
$font-size-h4: 1.25em !default;
$font-size-h5: 1.125em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content
$headings-colour: $grey-dark;


// Links
$link-hover-decoration: none;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-background-colour: $grey-dark;
$btn-border-radius: 3em;

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $grey-dark; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific

// Footer specific social icons
$social-icons-footer-colour: white; // A colour, or 'brand'


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-heading-colour: $grey-dark;
$card-heading-font-size: $font-size-h4;
$card-footer-background-colour: transparent;

// Card text overlay

// Card text over

// Card side by side
$card-side-by-side-breakpoint: 850px;

// Card hover state
$card-hover-image-opacity: .7 !default;
$card-hover-details-background-colour: $brand-secondary;
// $card-hover-heading-colour: white;
// $card-hover-summary-colour: white;


//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//


// Header content

// Tagline

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: darken($brand-primary, 10%);
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-item-colour: $grey-dark;
$nav-top-level-item-hover-background-colour: $grey-dark;
$nav-top-level-item-hover-colour: white;
$nav-top-level-chevrons-enabled: true;

// Submenus
// $nav-submenu-background-colour: $grey-dark;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-colour: $grey-dark;
$nav-submenu-item-hover-colour: white;
$nav-submenu-item-hover-background-colour: $grey-dark;

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: right; // left, center, right
$nav-normal-max-width: 100%; // $container-max-width


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-details-style: overlay !default; // overlay, adjacent or below

$carousel-details-position-y: center; // top, center, bottom
$carousel-heading-colour: $grey-dark;
$carousel-details-background-colour: $brand-primary;
$carousel-image-overlay: linear-gradient(90deg, rgba($grey-dark, 0.6) 25%, rgba($grey-dark, 0.4) 35%, rgba($grey-dark, 0) 50%); // Add a colour value or gradient here
$carousel-summary-colour: $grey-dark;
$carousel-details-padding: $spacer * 2;
$carousel-details-border-radius: 10px;

$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-details-margin-x: $spacer;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-font-size: 2em;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: transparent;
// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
$carousel-scroll-btn-border-radius: 2em;
$carousel-scroll-btn-margin-y: -2em; // Can use a negative value to get it to peek over the edge
$carousel-scroll-btn-size: 60px;
$carousel-scroll-btn-icon-font-size: 1.5em;
$carousel-scroll-btn-animation-prefab: 3; // Pick a number from 0 (off) to 3!

// Carousel after breakpoint
$carousel-mobile-details-background-colour: white;
$carousel-mobile-heading-colour: $grey-dark;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//

$home-intro-margin-top: 0;
$home-intro-padding-y: 0;
$home-intro-font-size: $font-size-h1;


//
// Home features
//

$home-features-background-colour: $brand-primary;
$home-features-cards-entry-animation-prefab: 2; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 3;


//
// Impact stats
//
$impact-stats-background-colour: white;
$impact-stats-padding-y: $spacer * 5;


// Heading
$impact-stats-heading-margin-bottom: 0;

// Individual stat

// Figure
$impact-stats-figure-colour: $brand-primary;
$impact-stats-figure-font-size: 5em;

// Summary
$impact-stats-summary-colour: $fresco-dark-font;


//
// Home feeds
//
$home-feeds-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations


// Feeds title
$feeds-title-margin-bottom: 0;

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: $grey-lightest;
$footer-prefab: 4;

// Footer admin links
$footer-admin-manager-link-enabled: false;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-heading-colour: $grey-dark;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-font-size: $font-size-h4;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 3;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//
$blockquote-border-radius: 0.75em;
$blockquote-background-colour: $brand-secondary;
$blockquote-colour: white;



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//


// Donation amounts


//
// Quick giving panel
//



//
// Home quick giving panel
//
$home-quick-giving-background-colour: $grey-dark;
$home-quick-giving-heading-colour: white;
$home-quick-giving-donate-btn-background-colour: white;
$home-quick-giving-donate-btn-colour: $grey-dark;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//
